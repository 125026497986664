<template>
  <v-container class="layout-container">
    <overview />
  </v-container>
</template>

<script>
export default {
  components: {
    Overview: () => import('@/modules/company')
  }
}
</script>
